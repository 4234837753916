<template>
  <div>
    <div v-if="showAddtoWatchlist" style="display:flex; align-items:center">
      <svg @click.stop="watchListHander" :width="svgWnH ? svgWnH : 46" :height="svgWnH ? svgWnH : 46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" :class="!svgWnH ? 'watch_list_svg' : ''">
        <circle cx="23" cy="23" r="22" stroke="#FFFFFF" stroke-width="2"/>
        <path d="M23.5 13.75C23.5 13.3358 23.1642 13 22.75 13C22.3358 13 22 13.3358 22 13.75V22H13.75C13.3358 22 13 22.3358 13 22.75C13 23.1642 13.3358 23.5 13.75 23.5H22V31.75C22 32.1642 22.3358 32.5 22.75 32.5C23.1642 32.5 23.5 32.1642 23.5 31.75V23.5H31.75C32.1642 23.5 32.5 23.1642 32.5 22.75C32.5 22.3358 32.1642 22 31.75 22H23.5V13.75Z" fill="white"/>
      </svg>
  </div>
    <div v-if="!showAddtoWatchlist" style="display:flex; align-items:center">
      <svg @click.stop="watchListHander" :width="svgWnH ? svgWnH : 46" :height="svgWnH ? svgWnH : 46" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg" :class="!svgWnH ? 'watch_list_svg' : ''">
        <circle cx="23" cy="23.5889" r="22" stroke="#FFFFFF" stroke-width="2"/>
        <path d="M13.75 23.3389L31.6904 23.3389" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
  </div>
</template>
  
  <script>
  
  import { mapGetters } from "vuex";
  import { eventBus } from "@/eventBus";
  import firebase from "firebase/app";
  import "firebase/database";
  import FirebaseActions from "@/mixins/FirebaseActions";
  import googleAnalytics from "@/mixins/googleAnalytics.js";
  import { _projectName } from "@/provider-config.js";
  import { showSnackBar } from '../../utilities';
  export default {
    props: {
      content: {
        type: Object,
      },
      width: {
        type: String,
      },
      height: {
        type: String,
      },
      screen: {
        type: Object,
      },
      svgWnH: {
        type: Number
      }
    },
    data() {
      return {
        showAddtoWatchlist: true,
        action: null,
        fillColor: null,
        colorCodeActive: '#FECA35',
        colorCodeDeactive: '#FFFFFF',
        BackCodeActive: '#FECA35',
        BackCodeDeactive: null,
        watchlistFlag: false
  
      };
    },
    watch: {
      content() {
        this.fetchButtonState();
      },
      subscriberid() {
        if (this.subscriberid) {
          this.fetchButtonState();
        }
      },
    },
    computed: {
      ...mapGetters(["subscriberid", "profileid"]),
    },
    mounted () {
    },
    created() {
      this.fetchButtonState();
      this.fillColor =
        _projectName === "VLIVE"
          ? "#FF9600"
          : _projectName === "NET5"
          ? "#FF9600"
          : _projectName === "NAMMAFLIX"
          ? "#ffffff"
          : _projectName === "Noor Play"
          ? "#ec1c65"
          : "";
    },
    methods: {
     
      watchListHander () {
        if (this.subscriberid) {
         this.watchlistFlag = !this.watchlistFlag;
          if (this.showAddtoWatchlist) {
            this.addToWatchList()
            showSnackBar(this.$t('Added to watchlist'))
  
          } else {
            showSnackBar(this.$t('removed from watchlist'))
  
            this.removeFromWatchlist()
          } 
        } else {
          let payload = {
            state: true,
            formType: "lookup",
          };
          eventBus.$emit("authPopup", payload);
        }
      },
      fetchButtonState() {
        if (!this.subscriberid) {
          this.showAddtoWatchlist = true;
          return;
        }
        if (!this.content) return;
  
        let content = this.content;
  
        let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;
  
        if ("seriesid" in content && content.seriesid) {
          // let seriesid = content.seriesid;
          path += `${content.objectid}/episodes/${content.objectid}`;
        } else {
          path += `${content.objectid}`;
        }
        firebase
          .database()
          .ref(path)
          .on("value", (snapshot) => {
            let response = snapshot.val();
            //   console.log("snapshot", snapshot.val());
            if (response && response.inwatchlist == true) {
              this.showAddtoWatchlist = false;
              this.action = "REMOVE";
            } else if (response && response.inwatchlist == false) {
              this.showAddtoWatchlist = true;
              this.action = "ADD";
            } else {
              this.showAddtoWatchlist = true;
              this.action = "ADD";
            }
            //   console.log("watchlist action", this.action);
          });
      },
      addToWatchList() {
        if (this.subscriberid) {
          this.showAddtoWatchlist = false;
          // this.content.orientation = this.screen.displayType;
          this.updateContentWatchlist(this.content, true);
          // if (this.action == "ADD") {
          //   console.log("update with add");
          //   this.updateContentWatchlist(this.content, true);
          // } else if (this.action == "REMOVE") {
          //   console.log("update with remove");
          //   this.updateContentWatchlist(this.content, false);
          // }
  
          //logging watchlist google analytics event.
          let GA = {
            action_name: "Add to watchlist",
          };
          this.userActionEvent(GA);
        } else {
          let payload = {
            state: true,
            formType: "lookup",
          };
          eventBus.$emit("authPopup", payload);
        }
      },
      removeFromWatchlist() {
        if (this.subscriberid) {
          this.showAddtoWatchlist = true;
          this.updateContentWatchlist(this.content, false);
  
          //logging watchlist google analytics event.
          let GA = {
            action_name: "Remove from watchlist",
          };
          this.userActionEvent(GA);
        }
      },
    },
    mixins: [FirebaseActions, googleAnalytics],
  };
  </script>
  
  
  <style lang="scss">
    @import "@/sass/_variables.scss";
    .fill-color {
      fill: $clr-stream-nxt-menu-icons;
    }

   @media screen and (min-width: 1441px) {
   .watch_list_svg {
     width: 46px !important;
     height: 46px !important;
   }
   }

   @media screen and (max-width: 1441px) {
    .watch_list_svg {
     width: 30px !important;
     height: 30px !important;
   }
   }
  </style>